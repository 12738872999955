@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap');

:root {
    --main-heading-color: rgb(37, 39, 41);
    --main-background-color: rgb(98, 144, 195);
    --main-background-color-300: rgb(98, 131, 195);
    --font-serif: 'Roboto Slab Bold', serif;
    --muted-text-opacity: .;
    --white: rgba(224, 231, 255);
    --white-300: rgba(249, 249, 249);
    --inner-content-width: 1000px;
    --border-radius: .25rem;
    --text-gray: rgb(55, 65, 81);
}

* {
    font-family: 'Roboto', 'sans-serif';
    box-sizing: border-box;;
}

body {
    margin: 0;
    background: rgb(243, 244, 246);
}

h1 {
    /* font-family: var(--font-serif); */
    font-size: 2.5rem;
    color: var(--white);
    font-weight: 400;
    font-style: italic;
    
}

.heading-container {
    padding: 4rem 1rem 4rem 1rem;
    background-color: var(--main-background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.heading-text {
    color: var(--main-heading-color);
}

.subheading-text {
    color: var(--white);
    font-weight: 100;
    text-align: center;
}

.form-container {
    border-radius: var(--border-radius);
    margin: 1rem auto 1rem auto;
    padding:2rem;
    max-width: var(--inner-content-width);
    display: flex;
    flex-direction: row;
    background: white;
}

@media only screen and (max-width: 600px) {
    .form-container {
        /* padding: 1rem; */
    }
}

#form-submit {
    margin: .5rem auto .5rem auto;
    max-width: var(--inner-content-width);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

#form-container-description {
    font-weight: 100;
    width: 45%;
    text-align: left;
}

.form-container-description-row {
    padding-top: 1rem;
}

@media only screen and (max-width: 600px) {
    .form-container-description-row {
        hyphens: auto;
        text-align: justify;
    }
}

b {
    font-weight: 400;
}

#well {
    background: rgb(243, 244, 246);
}

.form {
    width: 50%;
    display: flex;
}

.form div.fieldset:not(:first-child) {
    padding-top: 1rem;
}

.form-col {
    display: flex;
    flex-direction: column;
    margin-left: 50%;
}

@media only screen and (max-width: 600px) {
    .form-col {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
    }    
}

.form-col-n {
    padding-left: 4rem;
}

.canvas-container {
    margin: 1rem auto 1rem auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: var(--inner-content-width);
    overflow-x: hidden;
}

#performance {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    border-radius: var(--border-radius);
    margin-left: 1rem;
    padding: 1rem;
}

#performance-metrics {
    width: 100%;
    display: flex;
    /* align-items: center;
    /* flex-direction: column; */
    /* padding: 1rem; */ 
}

.metric-title {
    margin-top: 1rem;
    /* padding: 0 1rem 1rem 1rem; */
}

.metric-element {
    margin-top: 1rem;
    /* padding: 0 1rem 1rem 1rem; */
}

fieldset {
    padding: 0;
    border: none;
}

label {
    font-weight: 100;
}

button {
    padding: .5rem;
    border-radius: var(--border-radius);
    transition: background-color .25s;
    cursor: pointer;
}

.primary-button {
    background: var(--main-background-color);
    color: white;
    border: none;
}

.secondary-button {
    background: white;
    color: var( --text-gray);
    border: none;
}

.primary-button:hover {
    background: var(--main-background-color-300);
}

.secondary-button:hover {
    background: var(--white-300);
}

.canvas-n {
    margin-left: 1rem;
}

@media only screen and (max-width: 600px) {
    .canvas-n {
        margin-left: .5rem;
    }
}


canvas, canvas:active, canvas:focus {
    cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAAiElEQVQ4jdXOOw6CUBRF0dvJDEwcgDPBDzgyGgoN03g2JjYOSYJ87baNn9fd+2yAXa+THJE5RkpLHsKfAMYJyZvDPYz3xDrf/8uH8fnO4xuVi3AO4iJEXIGBrcYKah6cWBDhFC5Cwaej7Uj9HVQWvuRXo/MVF2/g9EHm8ZK15dKBGx0NzsSn1Qut984SIDg8HQAAAABJRU5ErkJggg==") 0 24, pointer;
    /* border-radius: var(--border-radius); */
    /* touch-action: none; */

}

/* canvas:active, canvas:focus {
    cursor: w-resize
} */

.canvas-container div {
    width: 50%;
}

.heading-container {
	background: linear-gradient(-75deg, var(--main-background-color), #bfc72e, #2193b3, #63a36b);
	background-size: 400% 400%;
	animation: gradient 20s ease infinite;
}

#social {
    position: absolute;
    top: 2rem;
    right: 2rem;
}

#gh {
    cursor: pointer;
    height: 32px;
    width: 32px;
}

#tw {
    cursor: pointer;
    height: 28px;
    margin-left: 1rem;
}

@media only screen and (max-width: 600px) {
    .dt-accent {
       display: none;
    }
}

@media only screen and (max-width: 600px) {
    #social {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: flex;
        flex-direction: column;
    }

    #tw {
        margin-top: 1rem;
        margin-left: 0;
    }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}